import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  createRef,
  memo,
  FC,
} from "react";
import loadable from "@loadable/component";
import { Request, Service } from "../";
import {
  Button,
  Form,
  Input,
  Modal,
  Image,
  Upload,
  Drawer,
  Popover,
} from "antd";
import {
  WarningOutlined,
  CaretRightOutlined,
  CloudDownloadOutlined,
  PauseOutlined,
  CloseOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  AudioOutlined,
  AudioMutedOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  PackageCodeType,
  Workshop,
  WorkshopService,
  WorkshopServiceTier,
} from "shared/src/data-models/Workshop";
import { CurrentUser } from "../../../../context/UserContext";
import {
  CommunicationAPI,
  PackagesAPI,
  AppointmentAPI,
} from "../../../../app/Services/axios";
import moment from "moment";
import {
  useWindowSize,
  debounce,
  useResizeObserver,
  tierCodeToDesc,
} from "../../../../app/Utilities";
import { getSasToken, getWorkshopServices } from "../../../../stores/selectors";
import VideoPlayerModal from "../../VideoPlayerModal";
import { BlobServiceClient } from "@azure/storage-blob";
import { CkMenuDotsIcon, TrashIcon } from "../../../../assets/SvgIcons";
import Compressor from "compressorjs";
import { serviceSAS, Appointment } from "shared";
import { WheelLoader } from "../../../../app/Components/Loaders";
import workshopPlaceholder from "../../../../assets/Images/workshop-placeholder.png";
import {
  Vehicle2Icon,
  CalendarCheckIcon,
  CKCloseIcon,
  CkPaperPlaneIcon,
  CkPaperClipIcon,
  CkInformationIconThin,
} from "../../../../assets/SvgIcons";
import "./styles.css";
import { CkButton, CkModal, CkMessage } from "../../../../CkUI";

const MicRecorder = loadable.lib(
  () => import("mic-recorder-to-mp3/dist/index.js")
);

const capitalizeText = (text: string) => {
  const str = text;
  const str2 = str.charAt(0).toUpperCase() + str.slice(1);
  return str2;
};

interface IProps {
  updateRequestList: Function;
  addRequestToList: Function;
  requestSelected: Request;
  setRequestSelected: Function;
  workshop: Workshop;
  reload: Function;
  loadingList: boolean;
  isMobileScreen: boolean;
  drawerContainer: "div" | "body";
  getDrawerContainer: () => HTMLElement;
}

interface Package {
  servicePackageCode: string;
  servicePackage: { servicePackageCode: string; servicePackageDesc: string };
  servicePackageMap: any[];
  servicePackageSetId: number;
  servicePackageType: {
    servicePackageTypeCode: string;
    servicePackageTypeDesc: string;
  };
  servicePackageTypeCode: string;
  price?: number;
  comment?: string;
}

interface ISelectService {
  message: string;
  categorie: string;
  service: string;
  serviceName: string;
  price: string;
  complement: string;
  avalible: boolean;
  errorAvalible: boolean;
  servicesItems: Array<any>;
}

interface IRet {
  avalible: boolean;
  service: string;
}
interface IService {
  comment: string;
  price: number;
  serviceCode: string;
  serviceName: string;
  avalible: boolean;
  errorValidate: boolean;
  serviceTypeCode?: string;
  serviceTypeName?: string;
  id?: number;
}

const textWithNewLines = (text: string, packages: any[] = []) => {
  const split = text.split("\n");
  return split.map((line, index) =>
    line === "%%QUOTE%%" ? (
      <h6
        className="blue"
        style={{
          margin: 0,
        }}
        key={`h6-new-line-${index}`}
      >
        Presupuesto
      </h6>
    ) : line === "%%AUTOREPLY%%" ? (
      packages.map((pack, packId) => (
        <p
          style={{
            color: "rgba(0, 0, 0, 0.8)",
            margin: 0,
            marginLeft: windowSize.width >= 992 ? "" : "5%",
          }}
          key={`p-new-line-${index}-${packId}`}
        >
          El precio del paquete{" "}
          <span style={{ color: "#2f66f2" }}>
            {pack.servicePackageDesc}&nbsp;
          </span>
          en su versión{" "}
          <span style={{ color: "#2f66f2" }}>
            {pack.servicePackageTypeDesc}&nbsp;
          </span>
          es de ${pack.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          MXN
        </p>
      ))
    ) : (
      <p
        className="standar"
        style={{
          color: "rgba(0, 0, 0, 0.8)",
          margin: 0,
        }}
        key={`p-new-line-${index}`}
      >
        {line}
      </p>
    )
  );
};

const AttachmentsMap = ({
  attached,
  sasToken,
  setVideoUrl,
}: {
  attached: Array<any> | undefined | any;
  sasToken: string | undefined;
  setVideoUrl: Function;
}) => {
  const IsImage = (url: string) => {
    const fileTypes: any[] = ["png", "jpg", "jpeg"];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const IsVideo = (url: string) => {
    const fileTypes: any[] = ["mp4", "avi", "flv", "wmv", "mov", "hvec"];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const IsAudio = (url: string) => {
    const fileTypes: any[] = [
      "mp3",
      "ogg",
      "mp4",
      "wav",
      "wave",
      "wma",
      "wmv",
      "oga",
      "mogg",
      "aac",
      "m4a",
      "3gp",
      "m4r",
    ];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const AudioAttachment: FC<{ url: string }> = ({ url }) => {
    const audioPlayer = useRef<HTMLAudioElement | null>(null);
    const [status, setStatus] = useState<"playing" | "paused" | "idle">("idle");
    const [durationTime, setDurationTime] = useState<string>("0:00");
    const [progress, setProgress] = useState<number>(0);
    const [currentTime, setCurrentTime] = useState<string>("0:00");

    const calculateProgress = () => {
      if (audioPlayer.current === null) return;
      if (
        audioPlayer.current.currentTime <= 0 ||
        audioPlayer.current.duration <= 0
      ) {
        setProgress(0);
      } else {
        setProgress(
          (audioPlayer.current.currentTime * 100) / audioPlayer.current.duration
        );
      }
      setCurrentTime(calculateTime(audioPlayer.current.currentTime));
    };

    const calculateTime = (secs: number) => {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    };

    const displayDuration = () =>
      audioPlayer.current &&
      setDurationTime(calculateTime(audioPlayer.current.duration));

    const tooglePlay = () => {
      if (audioPlayer.current === null) return;
      // if we are playing, let's pause
      if (audioPlayer.current.paused === false) {
        audioPlayer.current.pause();
        setStatus("paused");
        // else, let's play
      } else {
        audioPlayer.current.play();
        setStatus("playing");
      }
    };

    useEffect(() => {
      audioPlayer.current &&
        audioPlayer.current.addEventListener("loadedmetadata", () =>
          displayDuration()
        );
      audioPlayer.current &&
        audioPlayer.current.addEventListener("ended", () => setStatus("idle"));
      audioPlayer.current &&
        audioPlayer.current.addEventListener("timeupdate", () =>
          calculateProgress()
        );
    }, []);

    return (
      <div className="attached-container-audio">
        <CkButton
          shape="circle"
          variant="text"
          icon={
            status === "playing" ? <PauseOutlined /> : <CaretRightOutlined />
          }
          onClick={tooglePlay}
        />
        <div className="duration-display">
          <span>{currentTime}</span>
          <div className="outer-display">
            <div className="inner-display" style={{ width: `${progress}%` }} />
          </div>
          <span>{durationTime}</span>
        </div>
        <audio controls src={`${url}?${sasToken}`} ref={audioPlayer} />
      </div>
    );
  };

  if (attached.length === 0) return null;

  return attached.map((attachment: any, Aindex: number) =>
    sasToken && attachment.url !== "" && IsImage(attachment.url) ? (
      <div
        className="attached-container"
        key={`attachment-container-${Aindex}`}
      >
        <Image
          src={`${attachment.url}?${sasToken}`}
          alt={attachment.fileName}
          key={"img" + Aindex}
          rootClassName="comunication-attached-image"
        />
        <CkButton
          variant="text"
          shape="circle"
          color="mariner"
          icon={<CloudDownloadOutlined />}
          href={`${attachment.url}?${sasToken}`}
          target="_blank"
          key={"buttonID" + Aindex}
        />
      </div>
    ) : sasToken && attachment.url !== "" && IsVideo(attachment.url) ? (
      <div
        className="attached-container-video"
        key={`attachment-container-${Aindex}`}
      >
        <Button
          icon={<CaretRightOutlined />}
          onClick={() => {
            setVideoUrl(`${attachment.url}?${sasToken}`);
          }}
          className="mobile-size"
          key={"button" + Aindex}
        >
          {attachment.fileName}
        </Button>
        <CkButton
          variant="text"
          shape="circle"
          color="mariner"
          icon={<CloudDownloadOutlined />}
          href={`${attachment.url}?${sasToken}`}
          target="_blank"
          size="large"
          key={"buttonD" + Aindex}
        />
      </div>
    ) : sasToken && attachment.url !== "" && IsAudio(attachment.url) ? (
      <AudioAttachment
        url={attachment.url}
        key={`attachment-container-${Aindex}`}
      />
    ) : attachment.fileName.startsWith("Archivo(s) eliminado(s)") &&
      (attachment.url == undefined || attachment.url == null) ? (
      <p
        className="standar"
        style={{
          color: "rgba(0, 0, 0, 0.4)",
        }}
        key={"span" + Aindex}
      >
        {attachment.fileName}
      </p>
    ) : null
  );
};

const MessageCardSkeleton = () => (
  <div className="message-card outgoing skeleton">
    <div className="message-container">
      <div className="card-message">
        <div className="dot-flashing" />
      </div>
    </div>
  </div>
);

const MessageCard = ({
  request,
  sasToken,
  setVideoUrl,
}: {
  request: any;
  sasToken: string | undefined;
  setVideoUrl: Function;
}) => {
  if (request.message == "Conversación finalizada por inactividad.")
    return null;
  return (
    <div
      className={[
        "message-card",
        ...(request.isWorkshopMessage === false ? ["incoming"] : ["outgoing"]),
      ].join(" ")}
    >
      <div className="message-container">
        <div className="card-message">{textWithNewLines(request.message)}</div>

        {request.attachments.length > 0 && (
          <div className="attached-container-row">
            <AttachmentsMap
              attached={request.attachments}
              sasToken={sasToken ? sasToken : ""}
              setVideoUrl={setVideoUrl}
            />
          </div>
        )}
        <div className="card-date">
          <p>
            {moment(request.createdDate).format("DD/MMMM/YYYY")}{" "}
            {moment(request.createdDate)
              .add(-6, "hour")
              .format("h:mm a")
              .toUpperCase()}
          </p>
        </div>
      </div>
      {/* 
        {request.isWorkshopMessage === false && <AirplaneIcon />} 
      */}
    </div>
  );
};

const SideRequestDrawer: React.FC<IProps> = ({
  requestSelected,
  setRequestSelected,
  workshop,
  reload,
  loadingList,
  isMobileScreen,
  drawerContainer,
  getDrawerContainer,
}) => {
  /**
   *
   * States
   *
   **/
  const dispatch = useDispatch();
  const scrollArea = useRef<HTMLDivElement>(null);
  const intervalID = useRef<NodeJS.Timer | null>(null);
  const scrollMessagesRef = useResizeObserver<HTMLDivElement>(() => {
    calculateScrollHeight({ scrollMessages: true });
  });

  //Message Form
  const [messageForm] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  //Message
  const [newMessage, setNewMessage] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [reportText, setReportText] = useState<string>("");
  const [reportingUser, setReportingUser] = useState<boolean>(false);
  const [noText, setNoText] = useState<boolean>(false);
  const [noPrice, setNoPrice] = useState<boolean>(false);
  const [appointmentData, setAppointmentData] = useState<
    Appointment | undefined
  >();
  const [newService, setNewService] = useState<boolean>(false);
  const [requestServices, setRequestServices] = useState<Service[]>([]);
  const [userLogo, setUserLogo] = useState<string>("");
  const [wsLogo, setWsLogo] = useState<string>("");
  const user = React.useContext(CurrentUser);
  const [serviceCategories, setServiceCategories] = useState<any[]>([]);
  const [services, setServices] = useState<WorkshopService[]>([]);

  const [dataState, setDataState] = useState<Request>();
  const [servicesToAdd, setServicesToAdd] = useState<Array<ISelectService>>([]);
  const [packageToAdd, setPackageToAdd] = useState<any>();
  const [retService, setRetService] = useState<Array<IRet>>([]);
  const [initialServices, setInitialServices] = useState<Array<IService>>([]);
  const [categoriesItems, setCategoriesItems] = useState<Array<any>>([]);
  const [serviceItems, setServiceItems] = useState<Array<any>>([]);
  const [avalibleErr, setAvalibleErr] = useState<boolean>(false);

  const [modalClose, setModalClose] = useState<boolean>(false);
  const [modalRequest, setModalRequest] = useState<boolean>(false);
  const [isPackage, setIsPackage] = useState<boolean>(false);
  const [packageList, setPackageList] = useState<Package[]>([]);
  const [packageCodeList, setPackageCodeList] = useState<any[]>([]);
  const [packageTypeList, setPackageTypeList] = useState<any[]>([]);
  const [processingMessage, setProcessingMessage] = useState<boolean>(false);

  const sasToken: string = useSelector(getSasToken);

  const [formReport] = Form.useForm();
  const windowSize = useWindowSize();

  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [fileList, setFileList] = useState<any[]>([]);
  const [audioVisible, setAudioVisible] = useState<boolean>(false);
  const [containerClient, setContainerClient] = useState<any>();
  let blobService: any = null;
  const account = process.env.REACT_APP_BLOB_ACCOUNT as string;
  const containerName = "up-images-container";

  const { TextArea } = Input;

  const numbers = [
    "cero",
    "uno",
    "dos",
    "tres",
    "cuatro",
    "cinco",
    "seis",
    "siete",
    "ocho",
    "nueve",
    "veinte",
    "treinta",
    "cuarenta",
    "cincuenta",
    "sesenta",
    "setenta",
    "ochenta",
    "noventa",
  ];

  const getCg = () => {
    const services = [...workshop.services!];
    // let temp: Array<any> = []
    let arrayData: Array<any> = [];

    services.map((serv) => {
      arrayData.push({
        name: serv.serviceCategoryName,
        code: serv.serviceCategoryCode,
      });
    });

    const deleteDuplicates = (arr: Array<any>) => {
      const categorysMap = arr.map((category: any) => {
        return [category.code, category];
      });
      //@ts-ignore
      return [...new Map(categorysMap).values()];
    };

    setServiceCategories(deleteDuplicates(arrayData));
  };

  const textValidation = () => {
    const reEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const reLink =
      /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
    const reNum = /^\d+$/;
    let numTrash: string[] = [];
    let emailTrash = "";
    let linkTrash = "";
    let t = text;
    const words = t.replaceAll("\n", " | ").split(" ");
    words.forEach((word, index) => {
      if (word.replaceAll("-", "").match(reNum)) {
        // If word is a number
        if (word.replaceAll("-", "").length === 8 && numTrash.length === 0) {
          t = t.replaceAll(word, "********");
        } else {
          word.split("").forEach((a, index) => {
            if (index === word.length - 1) numTrash.push(a + " ");
            else numTrash.push(a);
          });

          emailTrash += " " + word;
          if (emailTrash.includes(".")) {
            if (emailTrash[emailTrash.length - 1] !== ".") {
              t = t.replaceAll(emailTrash, "********");
              emailTrash = "";
            }
          }
        }
      } else if (numbers.includes(word.toLowerCase().replaceAll(",", ""))) {
        numTrash.push(word + " ");
      } else if (word.match(reEmail)) {
        // If regex of email is true
        t = validateWithNumTrash(numTrash, t);
        numTrash = [];
        t = t.replaceAll(word, "********");
      } else if (word.match(reLink) && !emailTrash) {
        // If regex of link is true
        t = validateWithNumTrash(numTrash, t);
        numTrash = [];
        t = t.replaceAll(word, "********");
      } else {
        // everything else
        t = validateWithNumTrash(numTrash, t);
        numTrash = [];
        if (emailTrash) {
          emailTrash += " " + word;
          if (emailTrash.includes(".")) {
            if (emailTrash[emailTrash.length - 1] !== ".") {
              t = t.replace(emailTrash, "********");
              emailTrash = "";
            }
          }
        } else {
          if (linkTrash) {
            linkTrash += " " + word;
            if (
              linkTrash
                .replaceAll("punto", ".")
                .replaceAll(" ", "")
                .match(reLink)
            )
              t = t.replaceAll(linkTrash, "********");
            linkTrash = "";
          }
          if (word.includes(".")) {
            if (word[0] === ".") {
              if (index > 0) {
                if (word.length > 1) {
                  t = t.replaceAll(words[index - 1] + " " + word, "********");
                } else {
                  linkTrash = words[index - 1] + " " + word;
                }
              }
            } else if (word[word.length - 1] === ".") {
              if (word.length > 1) {
                linkTrash = word;
              } else {
                if (index > 0) {
                  linkTrash = words[index - 1] + " .";
                }
              }
            } else {
              if (word.match(reLink)) t = t.replaceAll(word, "********");
            }
          } else if (word.includes("punto")) {
            if (word.substr(0, 5) === "punto") {
              if (index > 0) {
                if (word.length > 5) {
                  if (
                    (words[index - 1] + word)
                      .replaceAll("punto", ".")
                      .match(reLink)
                  )
                    t = t.replaceAll(words[index - 1] + " " + word, "********");
                } else {
                  linkTrash = words[index - 1] + " " + word;
                }
              }
            } else if (word.substr(word.length - 5, word.length) === "punto") {
              if (word.length > 5) {
                linkTrash = word;
              } else {
                if (index > 0) {
                  linkTrash = words[index - 1] + " " + word;
                }
              }
            } else {
              if (word.match(reLink)) t = t.replaceAll(word, "********");
            }
          }
        }
        if (word.includes("@") || word.includes("arroba")) {
          linkTrash = "";
          if (word[0] === "@") {
            if (index > 0) {
              emailTrash = words[index - 1] + " " + word;
            }
          } else {
            if (word.substr(0, 6) === "arroba") {
              if (index > 0) {
                emailTrash = words[index - 1] + " " + word;
              }
            } else {
              emailTrash = word;
            }
          }
        }
        t = t.replace(word, validateNumWord(word));
      }
    });
    t = validateWithNumTrash(numTrash, t);
    return t;
  };

  const validateWithNumTrash = (numTrash: string[], t: string) => {
    const trash = numTrash.join("");
    if (numTrash.length >= 8) {
      return t.replaceAll(trash.substr(0, trash.length - 1), "********");
    }
    return t;
  };

  const validateNumWord = (str: string) => {
    const re = "^[0-9]";
    let word = str;
    let trash = "";
    for (let i = 0; i < word.length; i++) {
      if (word[i].match(re)) {
        trash += word[i];
      } else {
        if (trash.length === 8) {
          word = word.replaceAll(trash, "*******");
        } else {
          trash = "";
        }
      }
    }
    return word;
  };

  const sendResponse = () => {
    if (!text) return;
    let subText = "";
    setProcessingMessage(true);
    setNoText(false);
    handleMessage(subText);
  };

  const validateComment = (comment: string) => {
    if (!comment) return "";
    let c = comment;
    numbers.forEach((a) => (c = c.replaceAll(a, "****")));
    return c;
  };

  const handleMessage = (textProp: string) => {
    let subText = textProp;
    let fullText = textValidation() + subText;
    const subIva =
      "\n*Los precios incluyen IVA" +
      "\n*Los precios pueden variar una vez que se realice el diagnóstico físico";
    const subSrv = "\n%%QUOTE%%";

    if (!isPackage) {
      let newSevicesAvalible: Array<ISelectService> = servicesToAdd.filter(
        (resp: ISelectService) => resp.avalible == true
      );

      let servV: Array<ISelectService> = newSevicesAvalible.filter(
        (res) => res.service === ""
      );
      let priceV: Array<ISelectService> = newSevicesAvalible.filter(
        (res) => res.price === ""
      );
      let Arrylength: number = servV.length + priceV.length;

      let ftAvalible = initialServices.filter((resp) => resp.avalible == true);

      if (newSevicesAvalible.length > 0) {
        if (Arrylength === 0) {
          fullText = fullText + subSrv;
          Promise.resolve()
            .then(() => {
              if (ftAvalible.length > 0) {
                initialServices.map((srv: IService, index: number) => {
                  const editService = {
                    comment: srv.comment,
                    createdBy: user!,
                    createdById: parseInt(user!.id),
                    endConsumerRequestId: parseInt(requestSelected.id),
                    price: srv.price,
                    serviceCode: srv.serviceCode,
                    serviceName: srv.serviceName,
                  };
                  if (srv.avalible) {
                    if (srv.price > 0) {
                      let numFilteredToAdd: string = srv.price.toLocaleString();
                      const srvToAdd =
                        "\n - " +
                        srv.serviceName +
                        ": " +
                        "$" +
                        numFilteredToAdd +
                        "MXN";
                      const compToAdd =
                        srv.comment.length > 0 ? "\n" + srv.comment : "";
                      fullText = fullText + srvToAdd + compToAdd;
                      CommunicationAPI.updateService(editService)
                        .then((result) => {})
                        .catch((_error) => {});
                    }
                  }
                });
              }
              newSevicesAvalible.map((srv: ISelectService, index: number) => {
                const createdService = {
                  comment: srv.complement,
                  createdBy: user!,
                  createdById: parseInt(user?.id!),
                  endConsumerRequestId: parseInt(requestSelected.id),
                  price: srv.price,
                  serviceCode: srv.service,
                  serviceName: srv.serviceName,
                };
                let num: number = parseInt(srv.price);
                let numComma: string = num.toLocaleString();
                const srvToAdd =
                  "\n - " + srv.serviceName + ": " + "$" + numComma + "MXN";
                const compToAdd =
                  srv.complement.length > 0 ? "\n" + srv.complement : "";
                fullText = fullText + srvToAdd + compToAdd;
                CommunicationAPI.updateService(createdService)
                  .then((result) => {})
                  .catch((_error) => {});
              });
              fullText = fullText + subIva;
            })
            .finally(() => {
              attachFilesAndSendMessage(fullText);
            })
            .catch((err) => {
              CkMessage({
                type: "error",
                text: "Ocurrió un error al enviar el mensaje",
              });
            });
        } else {
          CkMessage({
            type: "error",
            text: "Asegurate de completar los campos requeridos",
          });
          setAvalibleErr(true);
          let data: Array<ISelectService> = [...servicesToAdd];
          let temp: Array<ISelectService> = [...servicesToAdd];
          data.map((srv, index) => {
            let tempTwo = { ...temp[index] };
            tempTwo.errorAvalible = true;
            temp[index] = tempTwo;
          });
          setServicesToAdd(temp);
        }
      } else {
        if (ftAvalible.length > 0) {
          fullText = fullText + subSrv;
          initialServices.map((srv: IService, index: number) => {
            const editService = {
              comment: srv.comment,
              createdBy: user!,
              createdById: parseInt(user!.id),
              endConsumerRequestId: parseInt(requestSelected.id),
              price: srv.price,
              serviceCode: srv.serviceCode,
              serviceName: srv.serviceName,
            };
            if (srv.avalible) {
              if (srv.price > 0) {
                let numFilteredToAdd: string = srv.price.toLocaleString();
                const srvToAdd =
                  "\n - " +
                  srv.serviceName +
                  ": " +
                  "$" +
                  numFilteredToAdd +
                  "MXN";
                const compToAdd =
                  srv.comment.length > 0 ? "\n" + srv.comment : "";
                fullText = fullText + srvToAdd + compToAdd;
                CommunicationAPI.updateService(editService)
                  .then((result) => {})
                  .catch((_error) => {});
              }
            }
          });
          fullText = fullText + subIva;
        }
        attachFilesAndSendMessage(fullText);
      }
    } else {
      const availPacks = initialServices.filter((resp) => resp.avalible);
      if (availPacks.length > 0) {
        fullText = fullText + subSrv;
        availPacks.forEach((srv: IService, index: number) => {
          const editService = {
            comment: srv.comment,
            createdBy: user!,
            createdById: parseInt(user!.id),
            endConsumerRequestId: parseInt(requestSelected.id),
            price: srv.price,
            servicePackageCode: srv.serviceCode,
            servicePackageDesc: srv.serviceName,
            servicePackageTypeCode: srv.serviceTypeCode,
            servicePackageTypeDesc: srv.serviceTypeName,
            servicePackageSetId: srv.id,
            workshopId: workshop?.id,
          };

          if (srv.price > 0) {
            let numFilteredToAdd: string = srv.price.toLocaleString();
            const srvToAdd =
              "\n - " +
              srv.serviceName +
              " - " +
              srv.serviceTypeName +
              ": " +
              "$" +
              numFilteredToAdd +
              "MXN";
            const compToAdd = srv.comment.length > 0 ? "\n" + srv.comment : "";
            fullText = fullText + srvToAdd + compToAdd;
            CommunicationAPI.updateService(editService)
              .then((result) => {})
              .catch((_error) => {});
          }
        });
      }
      if (packageToAdd) {
        fullText =
          fullText +
          subSrv +
          `\n${packageToAdd.servicePackageDesc} - ${packageToAdd.servicePackageTypeDesc}: $${packageToAdd.price} MXN \n${packageToAdd.comment}`;
        CommunicationAPI.updateService(packageToAdd);
      }
      attachFilesAndSendMessage(fullText);
    }
  };

  const attachFilesAndSendMessage = (fullText: string) => {
    let attachmentsList: any[] = [];
    fileList.forEach(async (file) => {
      if (file.file.type.toString().startsWith("image/"))
        attachmentsList.push(uploadFileAndCompress(file.file));
      else attachmentsList.push(uploadFile(file.file));
    });

    Promise.all(attachmentsList)
      .then((attachments) => {
        const sendingMessage = {
          Id: "",
          RequestId: requestSelected.id,
          IsWorkshopMessage: true,
          Message: fullText,
          CreatedDate: moment().toISOString(),
          Attachments: attachments,
          SenderName: workshop.name,
        };
        CommunicationAPI.message(sendingMessage)
          .then((res) => {
            CkMessage({
              type: "success",
              text: "Mensaje enviado exitosamente",
            });

            messageForm.resetFields();
            setNoText(false);
            setText("");
            setFileList([]);
            setProcessingMessage(false);
            reload();
          })
          .catch((err) => {
            console.error(err);
            CkMessage({
              type: "error",
              text: "Ocurrió un error al enviar el mensaje",
            });

            setProcessingMessage(false);
          });
      })
      .catch(() => setProcessingMessage(false));
  };

  const closeRequestWithReport = async () => {
    if (reportText.length === 0) {
      CkMessage({
        type: "warning",
        text: "Porfavor indica la razon del reporte.",
      });

      return;
    }

    setReportingUser(true);

    const sendingMessage = {
      CreatedBy: user.id,
      WorkshopId: workshop.id,
      EndConsumerRequestId: requestSelected.id,
      UserReportedId: requestSelected.endConsumer.id,
      Comment: reportText,
    };

    const closeRequestData = {
      Id: requestSelected.id,
    };

    const reportUser = await CommunicationAPI.doReport(sendingMessage)
      .then((res) => res)
      .catch((err) => {
        console.error(err);
        return false;
      });

    if (reportUser === false) {
      setReportingUser(false);
      CkMessage({
        type: "error",
        text: "Ocurrió un error al enviar el reporte.",
      });

      return;
    }

    const closeCom = await CommunicationAPI.closeRequest(closeRequestData)
      .then((res) => res)
      .catch((err) => {
        console.error(err);
        return false;
      });
    console.log("closeCom", closeCom);
    setReportingUser(false);
    setModalClose(false);
    CkMessage({
      type: "success",
      text:
        closeCom === false
          ? "Tu reporte ha sido enviado. Pero no pudimos cerrar la conversación."
          : "Tu reporte ha sido enviado exitosamente.",
    });
    reload();
  };

  const addService = () => {
    let temp: Array<ISelectService> = [...servicesToAdd];
    let ret: Array<IRet> = [...retService];

    temp.push({
      message: "",
      categorie: "",
      service: "",
      serviceName: "",
      price: "",
      complement: "",
      avalible: true,
      errorAvalible: false,
      servicesItems: [],
    });

    ret.push({
      avalible: true,
      service: "",
    });

    setRetService(ret);
    setServicesToAdd(temp);
  };

  const deleteService = (index: number) => {
    let temp: Array<ISelectService> = [...servicesToAdd];
    let tempTwo = { ...temp[index] };

    let ret: Array<IRet> = [...servicesToAdd];
    let retTwo = { ...ret[index] };

    tempTwo.avalible = false;
    temp[index] = tempTwo;

    retTwo.avalible = false;
    ret[index] = retTwo;

    // temp.splice(index, 1)
    // let filtered = temp.filter((res, i: number) => i != index)

    setRetService([...ret]);
    setServicesToAdd([...temp]);
  };

  const handlePackageToAdd = (code: number) => {
    const temp = packageList.find((a) => a.servicePackageSetId === code);
    const toAdd = {
      comment: temp?.comment,
      createdById: user?.id,
      packageItems: temp?.servicePackageSet.servicePackageMap,
      price: temp?.price,
      servicePackageCode:
        temp?.servicePackageSet.servicePackage.servicePackageCode,
      servicePackageDesc:
        temp?.servicePackageSet.servicePackage.servicePackageDesc,
      servicePackageSetId: temp?.servicePackageSetId,
      servicePackageTypeCode:
        temp?.servicePackageSet.servicePackage.servicePackageTypeCode,
      servicePackageTypeDesc:
        temp?.servicePackageSet.servicePackageType.servicePackageTypeDesc,
      workshopId: temp?.workshopId,
    };
    setPackageToAdd({ ...toAdd });
  };

  const updateCommit = (updater: any, index: number) => {
    let temp: Array<IService> = [...initialServices];
    let tempTwo = { ...temp[index] };

    tempTwo.comment = updater;
    temp[index] = tempTwo;

    setInitialServices(temp);
  };

  const updatePrice = (updater: any, index: number) => {
    let temp: Array<IService> = [...initialServices];
    let tempTwo = { ...temp[index] };

    tempTwo.price = updater;
    temp[index] = tempTwo;

    setInitialServices(temp);
  };

  const setPrice = (updater: string, index: number) => {
    let temp: Array<ISelectService> = [...servicesToAdd];
    let tempTwo = { ...temp[index] };

    tempTwo.price = updater;
    temp[index] = tempTwo;

    setServicesToAdd(temp);
  };

  const setComplement = (updater: string, index: number) => {
    let temp: Array<ISelectService> = [...servicesToAdd];
    let tempTwo = { ...temp[index] };

    tempTwo.complement = updater;
    temp[index] = tempTwo;

    setServicesToAdd(temp);
  };

  const setCategories = (updater: string, index: number) => {
    let temp: Array<ISelectService> = [...servicesToAdd];
    let tempTwo = { ...temp[index] };

    tempTwo.categorie = updater;
    temp[index] = tempTwo;

    setServicesToAdd(temp);
  };

  const getServicesItems = (code: string, index: number) => {
    if (code != undefined) {
      const temp = workshop.services!.filter(
        (serv) => serv.serviceCategoryCode === code
      );
      // let newSevicesAvalible: Array<ISelectService> = servicesToAdd.filter((resp: ISelectService) => resp.avalible == true)

      setHandleServices(temp, index);
    } else {
      let tempWait: Array<ISelectService> = [...servicesToAdd];
      let tempTwoWait = { ...tempWait[index] };

      tempTwoWait.servicesItems = [];
      tempWait[index] = tempTwoWait;
      setServicesToAdd(tempWait);
    }
  };

  const setHandleServices = (
    servicesProp: Array<WorkshopService>,
    index: number
  ) => {
    let temp: Array<any> = [];
    let final: Array<any> = [];

    if (servicesProp.length > 0) {
      servicesProp.map((res) => {
        temp.push({
          label: res.serviceName,
          key: res.serviceCode,
          value: res.serviceCode,
        });
      });

      let filter: Array<any> = [];
      dataState!.services.map((serviceData: any) => {
        filter.push(serviceData.serviceCode);
      });

      let filterByServiceSelected: Array<IRet> = retService.filter(
        (resp: IRet) => resp.avalible == true && resp.service != ""
      );

      final = temp.filter((res) => !filter.includes(res.key));

      let finalFiltered = final;
      if (filterByServiceSelected.length > 0) {
        // finalFiltered = final.filter(srv => srv.key != filterByServiceSelected.map(resp => resp.service))
        filterByServiceSelected.map((res) => {
          finalFiltered = finalFiltered.filter((srv) => srv.key != res.service);
        });
      }

      if (finalFiltered != undefined) {
        let tempWait: Array<ISelectService> = [...servicesToAdd];
        let tempTwoWait = { ...tempWait[index] };

        tempTwoWait.servicesItems = finalFiltered;
        tempWait[index] = tempTwoWait;

        // setServicesToAdd(temp)
        setServicesToAdd(tempWait);
      } else {
        let tempWait: Array<ISelectService> = [...servicesToAdd];
        let tempTwoWait = { ...tempWait[index] };

        tempTwoWait.servicesItems = [];
        tempWait[index] = tempTwoWait;
        setServicesToAdd(tempWait);
      }
    }
  };

  const setHandleRequest = (code: string, index: number) => {
    const indexData = requestServices.findIndex((a) => a.serviceCode === code);
    let temp: Array<ISelectService> = [...servicesToAdd];
    let tempTwo = { ...temp[index] };

    tempTwo.price = "";
    tempTwo.complement = "";
    temp[index] = tempTwo;

    setServicesToAdd(temp);
    // setPriceState("")
    // setComplementState("")
    if (indexData >= 0) {
      const serv = requestServices[indexData];
      let tempWait: Array<ISelectService> = [...servicesToAdd];
      let tempTwoWait = { ...tempWait[index] };

      tempTwoWait.price = serv.price.toString();
      tempTwoWait.complement = serv.comment;

      tempWait[index] = tempTwoWait;

      setServicesToAdd(tempWait);
      // setPriceState((serv.price).toString())
      // setComplementState(serv.comment)
    } else {
    }
  };

  const backUpServices = (updater: string, index: number) => {
    let temp: Array<IRet> = [...retService];
    let tempTwo = { ...temp[index] };

    tempTwo.service = updater;
    temp[index] = tempTwo;

    if (updater != undefined) {
      setRetService(temp);
    } else {
      tempTwo.service = "";
      temp[index] = tempTwo;
      setRetService(temp);
    }
  };

  const setLabels = (code: string, index: number) => {
    if (code != undefined) {
      let temp: Array<ISelectService> = [...servicesToAdd];
      let tempTwo = { ...temp[index] };

      let srvItem = workshop.services!.find((res) => res.serviceCode == code);

      tempTwo.serviceName = srvItem!.serviceName;
      tempTwo.service = code;

      temp[index] = tempTwo;

      setServicesToAdd(temp);
      // setServiceState(srvItem!.serviceName)
      // setServiceCodeState(code)
    } else {
      let temp: Array<ISelectService> = [...servicesToAdd];
      let tempTwo = { ...temp[index] };

      tempTwo.serviceName = "";
      temp[index] = tempTwo;

      setServicesToAdd(temp);
    }
  };

  const updateAvalible = (index: number) => {
    let temp: Array<IService> = [...initialServices];
    let tempTwo = { ...temp[index] };

    tempTwo.avalible = !tempTwo.avalible;
    temp[index] = tempTwo;
    setInitialServices(temp);
  };

  const IsImage = (url: string) => {
    const fileTypes: any[] = ["png", "jpg", "jpeg"];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const IsVideo = (url: string) => {
    const fileTypes: any[] = ["mp4", "avi", "flv", "wmv", "mov", "hvec"];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const IsAudio = (url: string) => {
    const fileTypes: any[] = [
      "mp3",
      "ogg",
      "mp4",
      "wav",
      "wave",
      "wma",
      "wmv",
      "oga",
      "mogg",
      "aac",
      "m4a",
      "3gp",
      "m4r",
    ];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const getFileDuration = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  const uploadProps = {
    name: "file",
    showUploadList: false,
    multiple: false,
    accept: "image/*, video/*, audio/*",
    beforeUpload(file: any) {
      return new Promise<File>(async (resolve) => {
        if (
          !file.type.startsWith("image/") &&
          !file.type.startsWith("video/") &&
          !file.type.startsWith("audio/")
        ) {
          CkMessage({
            type: "error",
            text: "Solo se soportan archivos png, jpg, jpeg, audio y videos de hasta 15 segundos y menores a 5 MB.",
          });

          return;
        }

        if (file.type.startsWith("video/") || file.type.startsWith("audio/")) {
          let duration = await getFileDuration(file);
          if (parseInt(duration) >= 16 || file.size > 1024 * 1024 * 5) {
            CkMessage({
              type: "error",
              text: "Solo se soportan archivos png, jpg, jpeg, audio y videos de hasta 15 segundos y menores a 5 MB.",
            });

            return;
          }
        }

        let fileUploaded = {
          uid: fileList.length.toString(),
          name: file.name,
          status: "done",
          file: file,
        };

        setFileList([...fileList, fileUploaded]);
      });
    },
  };

  const uploadFileAndCompress = async (file: any) => {
    return new Promise(async (resolve, reject) => {
      let date = new Date();
      let fileName = `${date.getTime()}_${file.name}`;
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);

      try {
        new Compressor(file, {
          quality: 0.6,
          convertTypes:
            "image/png, image/webp, image/gif, image/tiff, image/apng, image/ico, image/cur, image/ai, image/svg, image/raw, image/jfif",
          convertSize: 3000000,
          async success(result) {
            await blockBlobClient.upload(result, result.size);
            let attached = {
              fileName: file.name,
              url: blockBlobClient.url.split("?")[0],
              messageId: "0",
              id: "0",
            };
            resolve(attached);
          },
        });
      } catch (error) {}
    });
  };

  const uploadFile = async (file: any) => {
    return new Promise(async (resolve, reject) => {
      let date = new Date();
      let fileName = `${date.getTime()}_${file.name}`;
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);

      try {
        await blockBlobClient.upload(file, file.size);
        let attached = {
          fileName: file.name,
          url: blockBlobClient.url.split("?")[0],
          messageId: "0",
          id: "0",
        };
        resolve(attached);
      } catch (error) {}
    });
  };

  const getSasTokenAzure = async () => {
    dispatch(serviceSAS());
  };

  const validateForm = (e: any) => {
    setNewMessage(e);
  };

  /**
   * Calculate and set the height of scroll messages section
   * finalHeight = DrawerHeight - headerHeight - footerHeight
   * @params {
   *  scrollMessages: boolean - If it's true, after calculate height messages-scroll will be scrolled to bottom.
   * }
   */
  const calculateScrollHeight = ({
    scrollMessages = false,
  }: {
    scrollMessages: boolean;
  }) => {
    const container = scrollArea.current?.getBoundingClientRect()?.height || 0;
    const headerHeight =
      document
        .querySelector(".entry-request-drawer .ant-drawer-body .entry-header")
        ?.getBoundingClientRect()?.height || 0;
    const footerHeight =
      document
        .querySelector(".entry-request-drawer .ant-drawer-body .entry-footer")
        ?.getBoundingClientRect()?.height || 0;
    const messagesContainer: HTMLDivElement = document.querySelector(
      ".entry-request-drawer .ant-drawer-body .messages-scroll"
    );

    const antLayout = document.querySelector(".ant-layout");
    const navLayout = document.querySelector(".ant-layout-header");

    // First we need to set the paddings
    if (!!scrollArea.current)
      scrollArea.current.style.padding = `${headerHeight}px 0px ${footerHeight}px 0px`;

    // Now we need to set the Height for the messages container
    if (!!messagesContainer)
      messagesContainer.style.height = `${
        container - headerHeight - footerHeight
      }px`;
    if (scrollMessages && messagesContainer !== null)
      messagesContainer.scrollTop = messagesContainer?.scrollHeight || 0;

    // Time to block body scroll, if we don't do it Safair iOS works strange
    // We will block on screens smaller thant 860px width
    if (window.innerWidth > 860) {
      antLayout && antLayout.classList.remove("disable-scroll-div");
      navLayout && navLayout.classList.remove("ant-layout-header-in-drawer");
    } else {
      if (!!requestSelected == true) {
        antLayout && antLayout.classList.add("disable-scroll-div");
        navLayout && navLayout.classList.add("ant-layout-header-in-drawer");
      } else {
        antLayout && antLayout.classList.remove("disable-scroll-div");
        navLayout && navLayout.classList.remove("ant-layout-header-in-drawer");
      }
    }
  };

  const close = () => setRequestSelected(undefined);

  /**
   *
   * Components
   *
   */
  const AttachmentsButtons = () => (
    <>
      <Upload
        disabled={requestSelected.statusCode == "CLOS" ? true : false}
        {...uploadProps}
        fileList={fileList}
        accept="image/png, image/jpeg, image/jpg"
        //maxCount={undefined}
        multiple
      >
        <CkButton
          disabled={requestSelected.statusCode == "CLOS" ? true : false}
          variant="text"
          icon={<PictureOutlined size={22} />}
        />
      </Upload>

      <Upload
        disabled={requestSelected.statusCode == "CLOS" ? true : false}
        {...uploadProps}
        fileList={fileList}
        accept="video/mp4, video/avi, video/flv, video/wmv, video/mov"
        //maxCount={undefined}
        multiple
      >
        <CkButton
          variant="text"
          disabled={requestSelected.statusCode == "CLOS" ? true : false}
          icon={<VideoCameraOutlined size={22} />}
        />
      </Upload>

      <CkButton
        disabled={requestSelected.statusCode == "CLOS" ? true : false}
        onClick={(e) => {
          if (requestSelected.statusCode == "CLOS") {
            return null;
          } else {
            setAudioVisible(true);
            // gtmSetId(e.currentTarget);
          }
        }}
        variant="text"
        icon={<AudioOutlined size={22} />}
      />
    </>
  );

  const LocaleAttachmentsMap = ({
    fileList,
    setFileList,
  }: {
    fileList: Array<any> | undefined | any;
    setFileList: Function;
  }) => {
    const IsImage = (url: string) => {
      const fileTypes: any[] = ["png", "jpg", "jpeg"];
      const fileExtension = url?.split(".").pop();
      if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
      return false;
    };

    const IsVideo = (url: string) => {
      const fileTypes: any[] = ["mp4", "avi", "flv", "wmv", "mov", "hvec"];
      const fileExtension = url?.split(".").pop();
      if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
      return false;
    };

    const IsAudio = (url: string) => {
      const fileTypes: any[] = [
        "mp3",
        "ogg",
        "wav",
        "wave",
        "wma",
        "oga",
        "mogg",
        "aac",
        "m4a",
        "3gp",
        "m4r",
      ];
      const fileExtension = url?.split(".").pop();
      if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
      return false;
    };

    return (
      <div className="attached-representation-container">
        {fileList.map((file: any) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            {IsImage(file.name) ? (
              <PictureOutlined style={{ color: "var(--blue)" }} />
            ) : null}
            {IsAudio(file.name) ? (
              <AudioOutlined style={{ color: "var(--blue)" }} />
            ) : null}
            {IsVideo(file.name) ? (
              <VideoCameraOutlined style={{ color: "var(--blue)" }} />
            ) : null}
            <p className="three-dots-attached">{file.name}</p>
            <CloseOutlined
              style={{ color: "var(--black)" }}
              onClick={() => {
                setFileList(fileList.filter((f: any) => f.uid !== file.uid));
              }}
            />
          </div>
        ))}
      </div>
    );
  };
  const AudioModal = ({
    fileList,
    setFileList,
    audioVisible,
    setAudioVisible,
    user,
  }: {
    fileList: Array<any>;
    setFileList: Function;
    audioVisible: boolean;
    setAudioVisible: Function;
    user: any | undefined;
  }) => {
    const micRecorder = createRef();
    const recorderInstance = useRef<any>();
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [blobURL, setBlobURL] = useState<string>("");
    const [audioNewFile, setAudioNewFile] = useState<any>({});
    const [isBlocked, setIsBlocked] = useState<boolean>(false);
    const [newAudio, setNewAudio] = useState<boolean>(true);

    const [whichBrowser, setWhichBrowser] = useState<
      "Safari" | "Opera" | "Edge" | "Chrome" | "Firefox" | "unknown"
    >("Safari");

    useEffect(() => {
      getInfo();
    }, []);

    // useEffect(() => {
    //   if (whichBrowser == "Safari") {
    //     setIsBlocked(false);
    //   } else {
    //     //@ts-ignore
    //     navigator.getUserMedia(
    //       { audio: true },
    //       () => {
    //         setIsBlocked(false);
    //       },
    //       () => {
    //         setIsBlocked(true);
    //       }
    //     );
    //   }
    // }, [whichBrowser]);

    const getInfo = () => {
      if (
        (navigator.userAgent.indexOf("Opera") ||
          navigator.userAgent.indexOf("OPR")) != -1
      ) {
        setWhichBrowser("Opera");
      } else if (navigator.userAgent.indexOf("Edg") != -1) {
        setWhichBrowser("Edge");
      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        setWhichBrowser("Chrome");
      } else if (navigator.userAgent.indexOf("Safari") != -1) {
        setWhichBrowser("Safari");
      } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        setWhichBrowser("Firefox");
        // } else if (
        //   navigator.userAgent.indexOf("MSIE") != -1 ||
        //   !!document.DOCUMENT_NODE == true
        // ) {
        //   setWhichBrowser("IE");
      } else {
        setWhichBrowser("unknown");
      }
    };

    const start = () => {
      if (
        !!micRecorder.current === false &&
        !!recorderInstance.current === false
      )
        return;
      if (!!recorderInstance.current === false)
        recorderInstance.current = new micRecorder.current.default({
          bitRate: 128,
        });
      if (isBlocked) {
      } else {
        recorderInstance.current
          .start()
          .then(() => {
            setIsRecording(true);
            setBlobURL("");
            setAudioNewFile({});
            if (newAudio) setNewAudio(false);
          })
          .catch((e: any) => console.error(e));
      }
    };

    const stop = () => {
      if (
        !!micRecorder.current === false &&
        !!recorderInstance.current === false
      )
        return;
      if (!!recorderInstance.current === false)
        recorderInstance.current = new micRecorder.current.default({
          bitRate: 128,
        });
      if (isRecording)
        recorderInstance.current
          .stop()
          .getMp3()
          //@ts-ignore
          .then(async ([buffer, blob]) => {
            const videoDuration = user ? 15 : 5;
            const listSize = fileList.length + 1;
            const file = new File(buffer, `me-at-thevoice-${listSize}.mp3`, {
              type: blob.type,
              lastModified: Date.now(),
            });
            const duration = await getFileDuration(file);
            if (
              //@ts-ignore
              parseInt(duration) >= videoDuration + 1 ||
              file.size > 1024 * 1024 * 5
            ) {
              CkMessage({
                type: "error",
                text: `Solo se soportan archivos png, jpg, jpeg, audio y videos de hasta ${videoDuration} segundos y menores a 5 MB.`,
              });
            } else {
              setAudioNewFile(file);
              const blobURL = URL.createObjectURL(blob);
              setBlobURL(blobURL);
            }
            setIsRecording(false);
          })
          .catch((e: any) => console.error(e));
    };

    const getFileDuration = async (file: any) =>
      await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          //@ts-ignore
          const media = new Audio(reader.result);
          media.onloadedmetadata = () => resolve(media.duration);
        };
        reader.readAsDataURL(file);
        reader.onerror = (error) => reject(error);
      });

    const saveAudio = async () => {
      const prev = [...fileList];
      const listSize = prev.length + 1;
      const file = audioNewFile;
      const obj = {
        file: file,
        name: file.name,
        status: "done",
        uid: `u${listSize.toString()}`,
      };
      prev.push(obj);
      setFileList([...prev]);
      generateSasToken();
      setAudioVisible(false);
    };

    const generateSasToken = () => {
      // if (sasToken == undefined || sasToken == null) {
      /* ConfigurationsAPI.getSasTokenNonRegistered().then((response) => {
        sasToken = response.data;
        blobService = new BlobServiceClient(`${account}?${sasToken}`);
        setContainerClient(blobService.getContainerClient(containerName));
      }); */
      // }
    };

    return (
      <CkModal
        className="record-audio-modal"
        zIndex={5002}
        maskStyle={{ zIndex: 5002 }}
        open={audioVisible}
        footer={null}
        onCancel={async () => {
          await stop();
          setAudioVisible(false);
        }}
        title={!isRecording ? "Datos de la consulta" : "Grabando..."}
      >
        <MicRecorder ref={micRecorder} />
        {blobURL.length > 0 ? (
          <audio
            src={blobURL}
            //@ts-ignore
            controls="controls"
          />
        ) : null}

        {!isRecording && (
          <div className="audio-recorded">
            {blobURL.length > 0 ? (
              <CkButton
                className="next-button"
                onClick={() => saveAudio()}
                disabled={isRecording}
              >
                Hecho
              </CkButton>
            ) : null}
            <CkButton
              type={newAudio ? "default" : "link"}
              style={{ marginTop: "10px" }}
              onClick={() => start()}
              disabled={isRecording}
            >
              {newAudio ? "Abrir micrófono y grabar" : "Grabar nuevamente"}
            </CkButton>
          </div>
        )}
        {isRecording && (
          <div className="recording-audio">
            <CkButton
              color="danger"
              type="link"
              onClick={stop}
              disabled={!isRecording}
              icon={<AudioMutedOutlined />}
            >
              Terminar
            </CkButton>
          </div>
        )}
      </CkModal>
    );
  };

  const QuotationPopover = () => {
    const [customService, setCustomService] = useState<WorkshopService>();
    const [serviceTier, setServiceTier] = useState<WorkshopServiceTier>();
    const workshopServices = useSelector(getWorkshopServices);

    useEffect(() => {
      if (
        requestSelected &&
        requestSelected.typeCode === "QUOTE" &&
        requestSelected?.customService &&
        workshopServices &&
        workshopServices.length > 0
      ) {
        const findCustomService: WorkshopService | undefined =
        workshopServices.find(
            (customService: WorkshopService) =>
              customService.workshopServiceId === requestSelected.customService
          );
        if (findCustomService === undefined) return;
        if (
          requestSelected?.customServiceTier &&
          findCustomService.serviceTiers.length > 0
        ) {
          const findServiceTier = findCustomService.serviceTiers.find(
            (serviceTier) =>
              serviceTier.servicePackageTypeCode ===
              requestSelected.customServiceTier
          );
          setServiceTier(findServiceTier);
        } else {
          setServiceTier(undefined);
        }
        setCustomService(findCustomService);
      }
    }, [requestSelected, workshopServices]);

    if (customService === undefined) return null;

    return (
      <p>
        <b>
          {requestSelected.endConsumer !== null &&
          requestSelected.endConsumer !== undefined
            ? `${requestSelected.endConsumer.name} ${requestSelected.endConsumer.lastName}`
            : "El usuario"}
        </b>{" "}
        solicitó una <span>estimación de precio</span> para{" "}
        {requestSelected?.packages && requestSelected?.packages.length > 0
          ? "un"
          : "una"}{" "}
        <span>
          {customService.workshopServiceName}
          {serviceTier &&
            ` - ${tierCodeToDesc(
              serviceTier.servicePackageTypeCode as PackageCodeType
            )}`}
        </span>
      </p>
    );
  };

  const AppointmentPopover = () => {
    if (appointmentData === undefined) return null;
    return (
      <>
        <p className="title">Datos de la cita:</p>
        {appointmentData?.id && (
          <p>
            <b>Id:</b> {appointmentData?.id}
          </p>
        )}
        {appointmentData?.statusName && (
          <p>
            <b>Status:</b> {appointmentData?.statusName}
          </p>
        )}
        {appointmentData?.startDateTime && (
          <div className="date-container">
            <p>
              <b>Fecha:</b>
              {capitalizeText(
                moment(appointmentData?.startDateTime, "YYYY-MM-DDTHH:mmZ")
                  .format(" dddd ")
                  .toString()
              )}
              {moment(appointmentData?.startDateTime, "YYYY-MM-DDTHH:mmZ")
                .format("DD ")
                .toString()}
              de
              {moment(appointmentData?.startDateTime, "YYYY-MM-DDTHH:mmZ")
                .format(" MMMM, yyyy")
                .toString()}
              {moment(appointmentData?.startDateTime, "YYYY-MM-DDTHH:mmZ")
                .format("hh:mm a")
                .toString()}
            </p>
          </div>
        )}
      </>
    );
  };

  const VehiclePopover = () => {
    if (requestSelected?.vehicle === undefined) return null;
    return (
      <>
        <p className="title">Datos del vehiculo:</p>
        {(requestSelected.vehicle?.brandName ||
          requestSelected.vehicle?.modelName) && (
          <p>
            {[
              ...(requestSelected.vehicle?.brandName
                ? [requestSelected.vehicle?.brandName]
                : []),
              ...(requestSelected.vehicle?.modelName
                ? [requestSelected.vehicle?.modelName]
                : []),
            ].join(" ")}
          </p>
        )}
        {requestSelected.vehicle?.year && (
          <p>{requestSelected.vehicle?.year}</p>
        )}
        {requestSelected.vehicle?.licensePlate && (
          <p>{requestSelected.vehicle?.licensePlate}</p>
        )}
      </>
    );
  };

  const OptionsPopover = () => (
    <>
      {requestSelected.statusCode !== "CLOS" && (
        <CkButton variant="text" onClick={() => setModalClose(true)}>
          Reportar usuario
        </CkButton>
      )}
      <CkButton
        variant="text"
        ghost
        target="_blank"
        onClick={() => {
          window.open("/aviso-legal", "_blank");
        }}
      >
        Aviso legal
      </CkButton>
    </>
  );

  /**
   *
   * Effects
   *
   **/
  useLayoutEffect(() => {
    if (requestSelected === undefined) return;
    setDataState(requestSelected);

    let temp: Array<any> = [];

    if (requestSelected.services.length > 0) {
      requestSelected.services.map((res) => {
        temp.push({
          comment: res.comment,
          price: res.price,
          serviceCode: res.serviceCode,
          serviceName: res.serviceName,
          avalible: false,
          errorValidate: false,
        });
      });
      setInitialServices(temp);
    } else {
      setIsPackage(true);
      requestSelected.packages.map((res) => {
        temp.push({
          comment: res.comment,
          price: res.price,
          serviceCode: res.servicePackageCode,
          serviceName: res.servicePackageDesc,
          serviceTypeCode: res.servicePackageTypeCode,
          serviceTypeName: res.servicePackageTypeDesc,
          id: res.servicePackageSetId,
          avalible: false,
          errorValidate: false,
        });
      });
      setInitialServices(temp);

      PackagesAPI.getWorkshopPackages(requestSelected?.workshop?.id!)
        .then((res: any) => {
          const packages = res.data;
          const tempList = packages.reduce((prev: any[], curr: any) => {
            const code = curr.servicePackageSet.servicePackageCode;
            if (!prev.some((a) => a.code === code)) {
              prev.push({
                code,
                name: curr.servicePackageSet.servicePackage.servicePackageDesc,
                types: [
                  {
                    code: curr.servicePackageSet.servicePackageType
                      .servicePackageTypeCode,
                    name: curr.servicePackageSet.servicePackageType
                      .servicePackageTypeDesc,
                    setId: curr.servicePackageSet.servicePackageSetId,
                  },
                ],
              });
            } else {
              const index = prev.findIndex((a) => a.code === code);
              const temp = { ...prev[index] };
              temp.types.push({
                code: curr.servicePackageSet.servicePackageType
                  .servicePackageTypeCode,
                name: curr.servicePackageSet.servicePackageType
                  .servicePackageTypeDesc,
                setId: curr.servicePackageSet.servicePackageSetId,
              });
              prev.splice(index, 1, temp);
            }
            return prev;
          }, []);
          setPackageCodeList(tempList);
          setPackageList(res.data);
        })
        .catch(() => {
          setPackageList([]);
        });
      // });
    }
    // onLoad()
  }, [requestSelected]);

  useEffect(() => {
    if (requestSelected === undefined) return;
    setFileList([]);
    setText("");
    messageForm.resetFields();
    setRequestServices([...(requestSelected.services || [])]);
    if (requestSelected.workshopAppointmentId === null) {
      setAppointmentData(undefined);
    } else {
      AppointmentAPI.getAppById(requestSelected?.workshopAppointmentId)
        .then((response) => setAppointmentData(response.data))
        .catch((err) => setAppointmentData(undefined));
    }
  }, [requestSelected]);

  useEffect(() => {
    if (modalClose === false) {
      setReportText("");
      formReport.resetFields();
    }
  }, [modalClose]);

  useEffect(() => {
    intervalID.current = setInterval(getSasTokenAzure, 240000);
    return () => {
      intervalID.current && clearInterval(intervalID.current);
      intervalID.current = null;
    };
  }, []);

  useEffect(() => {
    if (sasToken) {
      blobService = new BlobServiceClient(`${account}?${sasToken}`);

      setContainerClient(blobService.getContainerClient(containerName));
    }
  }, [sasToken]);

  useEffect(() => {
    initialServices.map((srv: IService, index: number) => {
      if (srv.comment == null) {
        updateCommit("", index);
      }
    });
  }, [initialServices]);

  useEffect(() => {
    workshop && getCg();
  }, [workshop]);

  useEffect(() => {
    if (!loadingList) {
      setProcessingMessage(false);
    }
  }, [loadingList]);

  useEffect(() => {
    serviceCategories.map((res) => {
      let temp = categoriesItems;
      temp.push({
        label: res.name,
        key: res.code,
        value: res.code,
      });
    });
  }, [serviceCategories]);

  useEffect(() => {
    let temp: Array<any> = [];

    let final: Array<any> = [];
    setServiceItems([]);
    if (services.length > 0) {
      services.map((res) => {
        temp.push({
          label: res.serviceName,
          key: res.serviceCode,
          value: res.serviceCode,
        });
        // temp.push({
        //   comment: res.comment,
        //   price: res.price,
        //   serviceCode: res.serviceCode,
        //   serviceName: res.serviceName,
        //   // avalible: false
        // })
        // console.log("Temp data: ", temp)
      });

      let filter: Array<any> = [];
      dataState.services.map((serviceData: any) => {
        filter.push(serviceData.serviceCode);
      });

      final = temp.filter((res) => !filter.includes(res.key));

      if (final != undefined) {
        setServiceItems(final);
      } else {
        setServiceItems([]);
      }
    }
  }, [services]);

  useEffect(() => {
    dispatch(serviceSAS());
  }, []);

  useEffect(() => {
    if (!!requestSelected) {
      window.addEventListener("resize", () =>
        debounce(calculateScrollHeight({ scrollMessages: true }), 1000)
      );
    } else {
      window.removeEventListener("resize", () =>
        debounce(calculateScrollHeight({ scrollMessages: true }), 1000)
      );
    }
  }, [requestSelected]);

  useEffect(() => {
    if (videoUrl.length > 0) {
      setShowVideo(true);
    } else {
      setShowVideo(false);
    }
  }, [videoUrl]);

  useEffect(() => {
    calculateScrollHeight({ scrollMessages: true });
  }, [loading, fileList, requestSelected, isMobileScreen, processingMessage]);

  return (
    <div className={`entry-request-container contain-in-${drawerContainer}`}>
      <Drawer
        zIndex={2050}
        closable
        destroyOnClose
        title={null}
        footer={null}
        closeIcon={null}
        open={!!requestSelected}
        placement="bottom"
        className={`entry-request-drawer contain-in-${drawerContainer}`}
        getContainer={getDrawerContainer}
      >
        <WheelLoader mode="cover-container" spinning={loading} />
        {!!requestSelected && (
          <div className="entry-request-wrapper" ref={scrollArea}>
            <section className="entry-header">
              <figure className="figure-modal">
                <Image
                  className="image-modal"
                  src={`${requestSelected?.endConsumer?.photoUrl}?${sasToken}`}
                  fallback={workshopPlaceholder}
                  preview={false}
                  alt="Foto del usuario"
                />
                <figcaption>
                  <p>
                    {requestSelected?.endConsumer
                      ? `${requestSelected?.endConsumer?.name} - ${requestSelected?.endConsumer?.lastName}`
                      : "Usuario eliminado"}
                  </p>
                </figcaption>
              </figure>

              <div className="options-container">
                {appointmentData && (
                  <Popover
                    overlayClassName="entry-request-appointment-popover"
                    content={AppointmentPopover}
                    title={null}
                    trigger="click"
                    placement="bottomLeft"
                    arrowPointAtCenter
                    getPopupContainer={(e) => e}
                  >
                    <CkButton
                      variant="text"
                      shape="circle"
                      icon="calendar-grid"
                    />
                  </Popover>
                )}

                {requestSelected && requestSelected.typeCode === "QUOTE" && requestSelected?.customService && (
                  <Popover
                    overlayClassName="entry-request-quotation-popover"
                    content={QuotationPopover}
                    title={null}
                    trigger="click"
                    placement="bottom"
                    getPopupContainer={(e) => e}
                  >
                    <CkButton
                      variant="text"
                      shape="circle"
                      className="quotation-popover"
                      icon="information-round"
                    />
                  </Popover>
                )}
                {requestSelected && requestSelected?.vehicle && (
                  <Popover
                    overlayClassName="entry-request-vehicle-popover"
                    content={VehiclePopover}
                    title={null}
                    trigger="click"
                    placement="bottomLeft"
                    arrowPointAtCenter
                    getPopupContainer={(e) => e}
                  >
                    <CkButton
                      variant="text"
                      shape="circle"
                      icon="vehicle"
                      className="vehicle-icon"
                    />
                  </Popover>
                )}
                <Popover
                  overlayClassName="entry-request-options-popover"
                  content={OptionsPopover}
                  title={null}
                  trigger="click"
                  arrowPointAtCenter
                  getPopupContainer={(e) => e}
                >
                  <CkButton variant="text" shape="circle" icon="menu-dots" />
                </Popover>
                <CkButton
                  variant="text"
                  className="close-button"
                  icon="close"
                  onClick={close}
                  shape="circle"
                />
              </div>
            </section>

            <section className="messages-scroll" ref={scrollMessagesRef}>
              {((requestSelected &&
                requestSelected.typeCode === "QUOTE" &&
                requestSelected?.packages &&
                requestSelected?.packages.length > 0) ||
                (requestSelected &&
                  requestSelected.typeCode === "QUOTE" &&
                  requestSelected?.serviceTypes &&
                  requestSelected?.serviceTypes.length > 0)) && (
                <div className="quotation-message">
                  <p>
                    <b>
                      {requestSelected.endConsumer !== null &&
                      requestSelected.endConsumer !== undefined
                        ? `${requestSelected.endConsumer.name} ${requestSelected.endConsumer.lastName}`
                        : "El usuario"}
                    </b>{" "}
                    solicitó una <span>estimación de precio</span> para{" "}
                    {requestSelected?.packages &&
                    requestSelected?.packages.length > 0
                      ? "un"
                      : "una"}{" "}
                    <span>
                      {requestSelected?.packages &&
                      requestSelected?.packages.length > 0
                        ? `mantenimiento de ${requestSelected?.packages
                            .map((pack) => pack.servicePackageDesc)
                            .join(", ")}`
                        : `reparación de ${requestSelected?.serviceTypes
                            .map((service) => service.srvcTypeDesc)
                            .join(", ")}`}
                    </span>
                  </p>
                  {requestSelected.vehicle !== null && (
                    <p>
                      Vehículo:{" "}
                      <b>{`${requestSelected.vehicle.brandName}, ${requestSelected.vehicle.modelCode}, ${requestSelected.vehicle.year}`}</b>
                    </p>
                  )}
                  {requestSelected.messages
                    .filter(
                      (messageInfo, index) =>
                        messageInfo.isWorkshopMessage === true ||
                        messageInfo.isWorkshopMessage === false
                    )[0]
                    .message.startsWith("Vehículo") && (
                    <p>
                      Vehículo:{" "}
                      <b>
                        {requestSelected.messages
                          .filter(
                            (messageInfo, index) =>
                              messageInfo.isWorkshopMessage === true ||
                              messageInfo.isWorkshopMessage === false
                          )[0]
                          .message.split("\n\n")[0]
                          .replace("Vehículo: ", "")}
                      </b>
                    </p>
                  )}
                  <p>
                    <b>
                      Responde lo mas rapido posible para asegurar un buen
                      servicio al cliente
                    </b>
                  </p>
                </div>
              )}
              {requestSelected.messages
                .filter(
                  (messageInfo, index) =>
                    messageInfo.isWorkshopMessage === true ||
                    messageInfo.isWorkshopMessage === false
                )
                .map((messageInfo, index) => (
                  <MessageCard
                    sasToken={sasToken}
                    setVideoUrl={setVideoUrl}
                    request={messageInfo}
                    key={`message-card-${index}-${
                      messageInfo?.id || messageInfo?.requestId
                    }`}
                  />
                ))}

              {processingMessage && <MessageCardSkeleton />}
            </section>

            <section className="entry-footer">
              {fileList.length > 0 && (
                <div className="attachments-wrapper">
                  <p>Archivos adjuntos:</p>
                  <LocaleAttachmentsMap
                    fileList={fileList}
                    setFileList={setFileList}
                  />
                </div>
              )}
              <div className="submit-messages">
                <Form className="no-padding" form={messageForm}>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es obligatorio",
                      },
                    ]}
                  >
                    <TextArea
                      disabled={
                        requestSelected.statusCode == "CLOS" ? true : false
                      }
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      autoSize={{ minRows: 1, maxRows: 6 }}
                      placeholder={
                        requestSelected.statusCode === "CLOS"
                          ? requestSelected.messages.filter(
                              (messageInfo) =>
                                messageInfo.isWorkshopMessage == null ||
                                messageInfo.isWorkshopMessage == undefined
                            ).length > 0
                            ? requestSelected.messages[
                                requestSelected.messages.length - 1
                              ].message //"Conversación finalizada por inactividad."
                            : "La comunicación ha sido terminada."
                          : "Escribe tu mensaje"
                      }
                    />
                  </Form.Item>
                </Form>
                {/* requestSelected.statusCode !== "CLOS" && */}
                <Popover
                  content={<AttachmentsButtons />}
                  title={null}
                  trigger="click"
                  overlayClassName="attachments-popover"
                  zIndex={5001}
                >
                  <CkButton
                    variant="text"
                    className={`attachments-btn ${
                      requestSelected.statusCode === "CLOS" ? "hide" : ""
                    }`}
                    type="ghost"
                    shape="circle"
                    icon={<CkPaperClipIcon />}
                    disabled={requestSelected.statusCode === "CLOS"}
                  />
                </Popover>
                <CkButton
                  variant="text"
                  id="CONSUL013"
                  type="ghost"
                  shape="circle"
                  disabled={
                    requestSelected.statusCode === "CLOS"
                      ? true
                      : text.trim().length > 0
                      ? false
                      : true
                  }
                  className={`submit-btn ${
                    requestSelected.statusCode === "CLOS" ? "hide" : ""
                  }`}
                  loading={processingMessage}
                  // onClick={handleModalRequest}
                  onClick={sendResponse}
                  icon={<CkPaperPlaneIcon />}
                />
              </div>
            </section>
          </div>
        )}
      </Drawer>
      <CkModal
        className="report-user-modal"
        zIndex={5010}
        title={"Reportar usuario"}
        open={modalClose}
        onOk={closeRequestWithReport}
        onCancel={() => setModalClose(false)}
        primaryAction={{
          label: "Reportar",
          onClick: closeRequestWithReport,
          disabled: reportText.trim().length === 0,
        }}
        secondaryAction={{
          label: "Cancelar",
          onClick: () => setModalClose(false),
        }}
      >
        <WheelLoader mode="cover-container" spinning={reportingUser} />
        <WarningOutlined />
        <p>¿Por qué está reportando este usuario?</p>
        <Form form={formReport} initialValues={{ remember: false }}>
          <Form.Item
            name="message"
            rules={[
              { required: true, message: "Describe la razón del reporte" },
            ]}
            help="*Después de realizar el reporte se finalizará la conversación."
          >
            <TextArea
              rows={4}
              value={reportText}
              onChange={(txt) => setReportText(txt.target.value)}
            />
          </Form.Item>
        </Form>
      </CkModal>
      <Modal
        className="confirm-request-answer"
        title="Confirmación de solicitud"
        open={modalRequest}
        //className="my-modal"
        onOk={() => {
          setModalRequest(false);
          sendResponse();
        }}
        onCancel={() => {
          setModalRequest(false);
          setProcessingMessage(false);
        }}
        okText="Confirmar"
        okButtonProps={{
          className: "button_blue",
        }}
        cancelButtonProps={{
          style: {
            border: "none",
            color: "#979797",
            backgroundColor: "transparent",
            height: "40px",
          },
        }}
        cancelText={<span style={{ textDecoration: "underline" }}>Editar</span>}
        bodyStyle={{ textAlign: "center" }}
        zIndex={2000}
      >
        <WarningOutlined
          style={{ color: "var(--secondary-dark-color)", fontSize: "25px" }}
        />
        <br />
        <p style={{ fontSize: "25px", margin: 0 }}>
          {`${
            (requestSelected &&
              requestSelected.typeCode === "QUOTE" &&
              requestServices.length > 0) ||
            newService
              ? "Confirma que el precio de tu servicio ya incluya IVA"
              : "Confirma que el mensaje esta correcto"
          }`}
        </p>
        {((requestSelected &&
          requestSelected.typeCode === "QUOTE" &&
          requestServices.length > 0) ||
          newService) && (
          <p>
            * Los precios pueden variar una vez que se realice el diagnóstico
            físico
          </p>
        )}
      </Modal>
      <AudioModal
        fileList={fileList}
        setFileList={setFileList}
        audioVisible={audioVisible}
        setAudioVisible={setAudioVisible}
        user={user}
      />
      <VideoPlayerModal
        videoUrl={videoUrl}
        showVideo={showVideo}
        setShowVideo={setShowVideo}
      />
    </div>
  );
};

export default memo(SideRequestDrawer);
