
//React
import React, {
  FC,
  memo
} from "react";
//Antd
import { Image, Skeleton, Button } from "antd";

//utilities
import moment from "moment";

import {Request} from "../";

import workshopPlaceholder from "../../../../assets/Images/workshop-placeholder.png";

import { CkIcon, CkButton } from "../../../../CkUI";

import "./styles.css";


const RequestCardSkeleton = () => (
  <div
    className="request-card skeleton"
  >
    <figure>
      <Skeleton avatar title={false} paragraph={false} loading />
    </figure>
    <figcaption>
      <Skeleton title={false} paragraph={{rows: 3}} loading />
    </figcaption>
  </div>
);

const RequestCard = ({
  request,
  sasToken,
  goRequest,
  isActive
}: {
  request: any;
  sasToken: string | undefined;
  goRequest: Function;
  isActive: boolean;
}) => (
  <div
    className={["request-card", ...(isActive ? ["active"]:[])].join(" ")}
    onClick={(e) => goRequest(request)}
  >
    {request.statusCode === "PEND" &&
      <>
        <div className="main-vehicle-badge-triangle" />
        <CkIcon name="envelop" className="main-vehicle-badge" />
      </>
    }
    <figure>
      <Image
        src={`${request?.endConsumer?.photoUrl}?${sasToken}`}
        fallback={workshopPlaceholder}
        preview={false}
        alt={request.id}
      />
    </figure>
    <figcaption>
      <p className="nickname">
        {request?.endConsumer === null || request?.endConsumer === undefined ? "Usuario eliminado" : `${request?.endConsumer?.name} ${request?.endConsumer?.lastName}`}
      </p>
      <p className="compound-name">
        {moment(request.modifiedDate).format("DD/MMMM/YYYY")}{" "}
        {moment(request.modifiedDate)
          .add(-6, "hour")
          .format("h:mm a")
          .toUpperCase()}
      </p>
      <p className="compound-name">
        {request.endConsumerRequestType.requestTypeDesc}
      </p>
      {request.workshopAppointmentId &&
        <p className="appointment-id">
          Cita: {request.workshopAppointmentId}
        </p>}
    </figcaption>
  </div>
);


interface IProps {
  requestsList: any[];
  requestSelected: Request | undefined;
  setRequestSelected: Function;
  sasToken: string;
  loading: boolean;
  getData: Function;
  limitReached: boolean;
  setShowFiltersPopover: (show: boolean) => void;
}
const EntriesList: FC<IProps> = ({ 
  requestsList,
  setRequestSelected,
  requestSelected,
  sasToken,
  loading,
  getData,
  limitReached,
  setShowFiltersPopover
}) => {
  return (
    <div className="entries-list">
      {requestsList.length > 0 && requestsList.map((request, index) => (
        <RequestCard
          key={`request-card-${request?.id || request?.requestId || index}`}
          request={request}
          sasToken={sasToken}
          goRequest={setRequestSelected}
          isActive={!!requestSelected && !!request.id && !!requestSelected && parseInt(request.id) === parseInt(requestSelected.id)}
        />
      ))}        

      {requestsList.length <= 0 && loading === false &&
        <div className="no-request">
          <p>No se encontraron conversaciones con los filtros seleccionados</p>
          <CkButton
            variant="link"
            block={false}
            onClick={() => setShowFiltersPopover(true)}
          >
            Cambiar filtros
          </CkButton>
        </div>}

      {loading && [0, 1, 2, 3, 4, 5].map((index) => (<RequestCardSkeleton key={`request-card-skeleton-${index}`} />))}

      {!!loading === false && limitReached === false && requestsList.length !== 0 && (
        <CkButton
          variant="link"
          block={false}
          onClick={() => getData()}
        >
          Ver más
        </CkButton>)}
    </div>
  )
};

export default memo(EntriesList);
